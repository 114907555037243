import { marker } from '@ngneat/transloco-keys-manager/marker';

export const I18nKeys = {
	Sidebar: {
		Actions: {
			Open: marker('sidebar.actions.open'),
			Close: marker('sidebar.actions.close')
		}
	}
};
